
.Uploadmanager
  margin-bottom 20px
  background-color #F5F5F5
  padding 10px
  border-radius radius
  overflow scroll
  width 100%

.Uploadmanager,
.Uploadmanager-thumbs
  display flex

.Thumbnail
  position relative
  width 200px
  height 150px

  &-item
    width @width
    height @height
    display block
    display inline-block
    background-size cover
    background-position center center

  &-actions
    opacity 0
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    display flex
    justify-content center
    align-items center
    background-color rgba(black, 0.6)
    transition opacity 200ms ease-in-out
    &:hover
      opacity 1

  &-action
    display inline-block
    margin 0 5px
    background transparent
    background transparent
    border none
    color grey-lighter
    &:hover {color: white}

.Uploadmanager-thumbs
  .Thumbnail
    margin-left 10px
  .Thumbnail-item
    border 1px solid darken(#F5F5F5, 20%)
