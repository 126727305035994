.fa-boxed
  width 25px
  height @width
  position relative
  margin 0 6px
  text-align center
  display inline-block
  color #111
  > .fa
    font-size 15px
    line-height @width
    position relative
    z-index 1
  &:before
    content ""
    display inline-block
    width 100%
    height 100%
    position absolute
    top 0
    left 0
    border 1px solid #111
    border-radius 2px
    transform rotate(45deg)
    transition background-color border-color 0.27s cubic-bezier(0.300, 0.100, 0.580, 1.000)
  &:hover
    color white
    &:before
      background-color black

  // with backgroun
  &--black
    color white
    &:before {background-color: black}
  &--white
    color #111
    &:before {background-color: white; border-color: white}
