.button
  @extends $input-control
  padding: 3px 10px
  text-align: center
  white-space: nowrap
  line-height 32px
  strong
    color: inherit
  small
    display: block
    font-size: size-small
    line-height: 1
    margin-top: 5px
  .fa
    line-height 32px
    margin: 0 -2px
    width: 24px
  &:active
    box-shadow: inset 0 1px 2px rgba(black, 0.2)


button,
input[type="submit"]
  @extends .button

.button--ghost
  border inherit
  background transparent

// Button colors
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}
      color color
      background bg
      border-color transparent
      &:hover
        color white
        background darken(bg, 20%)
    &--{name}:not(.button--ghost)
      color color
      background bg
      border-color transparent

// Buttons hovers hovers
.button
  for c in button-colors
    name = unquote(c.name)
    color = c.color
    bg = c.bg
    &--{name}
      &:hover,
      &:focus
        background darken(bg, 16%)
        color color
        border-color transparent

  &-blue
    background-color transparent
    border-color white
    color white
    font-weight bold
    padding 3 20px
    transition all 500ms ease
    border-radius 0px

    &:hover
      background-color white
      border-color white
      color rgba(blue, 75%)
