
.Post

  &-list
    max-width 1200px
    margin 0 auto

  &-title
    margin-bottom 15px
    font-weight bold
    color grey-darker
    a
      font-weight bold
      color grey-darker  

  &-item
    height 420px !important
    border-bottom 0px !important

  &-meta_comments
    display none

  &-content
    position: relative
    line-height 1.2
    padding 0 30px
    p
      font-size size-5

    &:before
      content ' '
      background-image url('/assets/img/quote_small_1.png')
      position: absolute;
      top: -15px;
      left: 0px;
      background-repeat: no-repeat;
      background-position: center;
      height: 28px;
      width: 30px;
      display: block;
    &:after
      content ' '
      background-image url('/assets/img/quote_small_2.png')
      position: absolute;
      bottom: -15px;
      right: 0px;
      background-repeat: no-repeat;
      background-position: center;
      height: 28px;
      width: 30px;
      display: block;

  &-footer
    float right
    font-weight 600
    color black
    a
      color black

  &-image
    padding 15px
    width 200px
    margin 0 auto

  @media (min-width: 400px)
    &-item
      height 385px !important

  @media (min-width: 1024px)
    &-item
      height 250px !important

      .wrapper
        display flex
        align-items center

        &-image
          width 27%

        &-text
          width 78%
    &-image
      width 250px

    &-content
      padding 0 60px
      p
        font-size size-3
      &:before
        top: -30px;
        left: 0px;
        height: 56px;
        width: 60px;
        background-image url('/assets/img/quote_1.png')
      &:after
        bottom: -20px;
        right: 0px;
        height: 56px;
        width: 60px;
        background-image url('/assets/img/quote_2.png')


.testimonials-slider
  margin 40px auto
  h3, p
    text-align: center
  p
    margin-bottom: 0
  h3
    font-weight: bold
    margin-top: 0
  .testimonials-up, .testimonials-down
    border: 0
    background-color: transparent
    width: 10%
    background-position: center center
    background-repeat: no-repeat
    position: absolute
    left: 45%
    z-index: 1
  .testimonials-up
    background-image: url("/assets/img/arrow_up.png")
    top: -5%
  .testimonials-down
    background-image: url("/assets/img/arrow_down.png")
    bottom: -8%

  @media (min-width: 1024px)
    margin 60px auto
    .testimonials-up
      top: -10%
    .testimonials-down
      bottom: -20%

body.logged-in
  .Post
    &-item
      height initial !important
      .wrapper
        display initial

    &-content
      &:before, &:after
        content None !important

