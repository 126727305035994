body.logged-in
  .header-container
    position relative !important
    margin-top 0px !important
    &.inverted
      position relative !important

.header-container
  transition: all 0.5s ease-in-out;
  position fixed
  width: 100%
  z-index: 2
  .top-header
    display: none
    flex-flow: row nowrap
    align-items: center
    justify-content: space-between
    padding: 1px 5%
    background-color: rgba(0, 0, 0, 0.8)
    text-align center
    &-logo
      width: 100%
      img
        max-width: 150px
        width 100%
    &-contact
      width 100%
      margin-bottom 10px
      display flex
      flex-direction column
      align-items center
      .contact-networks
        display: flex
        flex-flow: row nowrap
        align-items: center
        justify-content: flex-end
        margin-bottom 10px
        a
          margin-right: 10px
        span
          color: white
          font-size: 0.9em
        .link
          margin-top 2px
          img
            width 80%
        .social
          background-color white
          border-radius 50%
          height 30px
          width 30px
          display flex
          align-items center
          justify-content center
          .fa
            color rgba(black, 80%)

      .translate
        span
          color white

      .contact-info
        display: flex
        flex-flow: row nowrap
        align-items: center
        justify-content: flex-end
        margin-top 5px
        a
          margin: 0px 5px
        img
          width: 20px
        span
          color: white
          font-size: 0.9em

    &-text
      display none

  &.inverted
    .top-header
      display block

  @media (min-width: 450px)
    .top-header
      &-logo
        width 30%
        max-width: 250px
      &-contact
        width 70%
        align-items center
        margin-bottom 0px
        flex-flow row wrap
        justify-content center
        .contact-networks
          margin-bottom 5px
    &.inverted
      .top-header
        display flex

  @media (min-width: responsive-breakpoint)
    .top-header
      &-contact
        width 70%

        .contact-networks
          margin-bottom 0px

      .contact-info
        img
          width: 40px

      &-text
        display block

  @media (min-width: normal-breakpoint)
    .top-header
      padding 12px 5% 5px
      &-contact
        justify-content flex-end
      &-logo
        img
          width 60%
      .contact-networks
        span
          font-size 1em
        .link
          margin-top inherit
          img
            width 100%

      .contact-info
        span
          font-size: 1.3em
        a
          margin: 5px 10px

.header-additional-container
  .header-container
    &.inverted
      position relative
  @media (min-width: responsive-breakpoint)
    position: relative
    background-size: cover

.banner-container
  padding: 0
  width: 100%
  z-index: 1
  box-shadow 0 1px 8px rgba(123,123,123,.8)
  .ImageList
    margin-bottom: 0

#google_translate_element
  display inline-block
  margin-bottom 5px

  .goog-te-gadget-simple
    background-color transparent
    border-left: transparent
    border-top: transparent
    border-bottom: transparent
    border-right: transparent

  @media (min-width: normal-breakpoint)
    margin-left 15px

.fa-bars
  pointer-events none
