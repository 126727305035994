
@keyframes fade-from-up
  from
    transform translate3d(0,30px,0)
    opacity 0
  to
    transform translate3d(0,0,0)
    opacity 1


@keyframes fade-from-down
  from
    transform translate3d(0,-30px,0)
    opacity 0
  to
    transform translate3d(0,0,0)
    opacity 1
