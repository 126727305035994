
/**
 *
 * Generic Toggles
 *
 */

@keyframes go-in-from-top
  from
    opacity 0
    transform translate3d(0,-30px,0)
  to
    opacity 1
    transform translate3d(0,0,0)

.tabpanel
  display none
  &.active
    display block
    animation go-in-from-top 250ms ease-out
