.paginator
  align-items: center
  justify-content: center
  a
    margin-right: 5px
  &-selected
    font-weight: bold
    color: dark-blue
  &-button
    color: grey-dark
