
.Listing-list
  list-style-type none
  .Listing-hero
    margin-bottom 10px
    text-align center

  .Listing-photo
    width 100%
    height 300px
    background-size cover !important
    position: relative
    &-status
      position: absolute
      top: 0
      left: 0
      width: 100%
      text-align: center
      padding: 5px
      color: white
      text-transform: uppercase
      font-weight bold
    .AVL
      background-color: rgba(110, 189, 110, 0.85)
    .RNT
      background-color: rgba(255, 220, 150, 0.85)
    .SLD
      background-color: rgba(255, 150, 150, 0.85)

  .Listing
    padding 0 20px

  .table
    margin-top 24px

  @media (min-width: 800px)
    display flex
    flex-flow row wrap
    .Listing
      width 33.33%
      display flex
      > .wrapper
        display flex
        flex-direction column
        width 100%
      .content
        flex 1 0 auto
    .Listing-photo
      height 200px


.Listing
  margin-bottom 50px
  > .wrapper
    border 1px solid grey
    transition border-color 250ms ease-in
    border-radius 2px
  &:hover
    > .wrapper
      border-color darken(grey-dark, 60%)
  .content,
  .title
    margin 5px 0

.Listing-area
  padding 0 15px

.Listing-price
  background-color blue
  color white
  font-size 1.5rem
  font-weight 700
  text-align center
  padding 5px 0px 10px 0px
  border-bottom 1px solid dark-blue

.Listing-price__legend
  font-size 12px
  font-weight normal
  text-transform uppercase
  line-height 1


.Listing-group
  > .title
    padding-bottom 5px
    border-bottom 1px solid gray


// Single
.Listing-single
  margin 16px auto 60px
  max-width 1000px
  width 95%

  .Grid
    max-width 700px
    margin 0 auto

  .Listing-price
    background-color transparent
    color dark-blue
    text-align right
    padding 10px 0
    margin-bottom 0
    border-bottom 0px
  .Listing-single-left
    max-width 400px
    margin-left auto
    margin-right auto
  .Listing-attributes
    table
      width auto
    td
      padding-right 20px

  @media screen and (min-width: 760px)
    display flex
    flex-flow row wrap
    .Listing-single-left
      width 30%
      max-width inherit
      margin-right 3%
      order 1
    .Listing-single-content
      width 100%
      padding 0 20px
      order 2

.Listing-single-left
  .Listing-area
    margin 10px 0
    text-align center

.Listing-single-content
  h1:first-of-type,
  h2:first-of-type
    margin-top 0

  .Content
    margin-bottom 0px

    h1
      font-size 1.5em
      margin-bottom 10px

  .Node,
  .Container
    padding-left 0
    padding-top 0

.Listing-content
  height 100%
  padding 0 15px
  margin 5px 0
  table
    margin-top 5px
    width auto

  th, td
    text-align center
    padding 0 5px
    font-size 12px

  th:not(.area), td:not(.area)
    border-right 1px solid grey

table
  width 100%
.table
    th
      border-bottom 3px solid color-light-gray
      padding 10px
      text-align left
    tr
      border-bottom 1px solid color-light-gray
      &:hover
        background-color lighten(color-light-gray, 10%)
    td
      padding 8px 15px

.Listing-nav
  fixed right 18px
  top 50%
  .item
    width 16px
    height 16px
    border-radius 8px
    border 1px solid #5F5F5F
    display block
    margin 5px 0
    > span
      clip rect(1px, 1px, 1px, 1px)
      position absolute
    &:hover
      background-color #5F5F5F

.Featured-listings
  max-width 960px
  margin 0 auto
  display flex
  flex-flow row wrap
  .Node
    background-size cover !important
    color white
    min-height 184px
    position relative
    margin-bottom 20px
    overflow hidden
    &:before
      content ''
      absolute top 0 bottom 0
      left 0
      right 0
      background-color rgba(black, .3)
      transition background-color 250ms ease-in
      z-index 0
    &:hover:before
      background-color rgba(black, .65)
    a
      color white
    h2, p
      position relative
      z-index 1
    h2
      font-size 2rem
      margin 0 0 5px

.listing-actions
  width 95%
  max-width 1000px
  margin 40px auto
  padding-bottom 24px
  border-bottom 2px solid grey-light

// gallery
.Listing-gallery
  margin-bottom 5px
  .featured
    min-height 300px
    height 55vh
    width 100%
    background-size cover !important
    text-align center
    position relative
    > span
      absolute bottom 10% right 5%
      height 40px
      line-height 40px
      padding 0 16px
      background-color rgba(black, 0.6)
      color white
      border-radius 5px
      transition background-color 400ms ease-in-out
    &:hover > span
      background-color black
  .images
    display none

.Listing-map + .Contact
  margin-top 0

// Ameneties
.Listing-ameneties
  .title
    font-weight 700
    padding 10px
    border-bottom 3px solid color-light-gray
    margin-bottom 20px
  ul
    list-style-type none
    display flex
    flex-flow row wrap
  li
    width calc(33.33% - 15px)
    padding 0 5px
    margin-bottom 15px

.Listing-map iframe
  width 100% !important
  height 55vh !important

#map
  min-height 320px
  max-height 400px
  height 22vw
  img
    max-width: none;

@keyframes mapOverlay
  from
    transform translate3d(0,0,0)
  to
    transform translate3d(0, -90%, 0)

.list-map
  width 100%
  display block
  position relative
  margin-top 20px
  overflow hidden

  &-content
    text-align center
    opacity 1
    transition opacity 120px ease-in
    p
      margin-top 18px
      letter-spacing 5px
      color white

  &-overlay
    absolute top 0 left 0
    right 0
    bottom 0
    background-color rgba(black, .8)
    display flex
    align-items center
    width 100%
    justify-content center
    transition all 200ms ease-in
    cursor pointer

    &-animate
      top inherit
      height 50px

      .list-map-content
        opacity 0

.Listing-areas
  display flex
  margin 20px auto
  justify-content center
  align-items center
  list-style-type none
  li
    margin 0 5px
  a
    display block
    padding 8px 20px
    background-color color-light-gray
    font-weight 700
    color color-primary
    border-radius 5px
    &:hover,
    &.active
      background-color color-primary
      color white

.Listing-inline-nav
  display flex
  list-style-type none
  justify-content center
  align-items center
  font-size 20px
  li
    margin 0 20px

// Island Life
.Island-life
  @media (min-width: 700px)
    display flex
    flex-flow row wrap
    .Listing-land
      width 55%
      margin-right 5%
    .Tax
      width 40%
  @media (min-width: 800px) and (max-width: 1500px)
    .Listing-list .Listing
      width 50%

// Tax
.Tax
  .tab
    display block
    padding  10px 20px
    border-bottom 1px solid black
    color black
    text-weight 700
    &:hover
      background-color lighten(color-light-gray, 10%)
    &.active
      background-color color-light-gray

  .tabpanel
    padding 14px
    &.active
      display block

  > ul
    list-style-type none

// Listings breadcrumb
.Breadcumb
  width 95%
  max-width 1000px
  margin 0px auto
  a
    color black
    &:hover
      text-decoration underline
    &:after
      content '/'
      padding 0 10px
  span
    color darken(color-light-gray, 40%)

  @media (min-width: normal-breakpoint)
    margin 60px auto 0


// LISTINGS CREATE
.Listing-modal_form
  > h3
    text-align center
  select
    width 100%
    margin 8px 0
  textarea
    resize vertical
    height 150px
  a
    color #444
    text-decoration underline

.Listing-modal_pager
  display block
  display flex
  margin 20px auto 36px
  padding 0
  > .tab
    color gray
    display inline-block
    padding 5px 20px
    border-bottom 4px solid color-light-gray
    text-decoration none
    &.active,
    &:focus,
    &:hover
      border-bottom-color color-secondary
      text-decoration none

// Attributes
#attributes
  > div:first-of-type
    display flex
  .attributes-select
    margin-right 14px
    width 80%
  button.add
    width calc(20% - 14px)
