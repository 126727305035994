.pager-menu-item
  color darken(grey, 20%)
  display block
  margin-top 7px
  margin-bottom 7px
  padding 7px 7px 7px 20px
  transition all 200ms easing
  &:hover
    color grey-dark
    transform translate3d(5px, 0, 0)
  &--active
    color white
    background-color grey-dark
    &:hover
      color white

.pager-menu
  padding size-normal
  padding-right 0
  background-color grey-lighter
  border-bottom 2px solid darken(grey-lighter, 10%)
  margin-bottom 30px
  overflow hidden
  @media (max-width: 700px)
    font-size 1.1rem
    display flex
    flex-flow row nowrap
    overflow-x auto
    align-items center
    .pager-menu-item
      padding-left 20px

.publish-pager
  @media (min-width: 700px)
    display flex
    max-width 950px
    .pager-content
      width 60%
      padding 30px 0
    .pager-menu
      width 27%
      margin-right 3%
      border-right 2px solid darken(grey-lighter, 10%)
      border-bottom none
      margin-bottom 0

// Navigation
.pager-navigation
  clearfix()
  margin-top 30px
  padding-top 10px
  border-top 1px solid darken(grey-lighter, 10%)

  &--next,
  &--finish
    @extends .pull-right
