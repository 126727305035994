.analytics-per-listing
  form
    display: flex
    flex-flow: row nowrap
    align-items: center
    justify-content: space-between
    max-width: 800px
    margin: 0 auto
    margin-bottom: 20px
    input
      width: 44%
    button
      width: 11%
  table
    max-width: 800px
    margin: 0 auto
    td, th
      text-align: center
      width: 50%
      padding: 10px
    th
      color: white
      background-color: dark-blue
    th:first-child
      border-top-left-radius: 10px
    th:last-child
      border-top-right-radius: 10px
    td
      font-size: 18px
    tr:nth-child(odd)
      background-color: grey-lighter
