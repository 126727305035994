
@keyframes modal-fade-in
  from
    opacity 0
    transform translate3d(0,0,0) scale(1.5)
  to
    transform scale(1)
    opacity 1

.Modal
  fixed top 0 bottom 0
  right 0
  left 0
  background-color rgba(black, 85%)
  color white
  z-index 12
  display none
  > .wrapper
    padding 6% 10%
    absolute top 0 bottom 0
    left 0
    right 0
    overflow-y scroll
  h3
    font-size 2.2rem
    margin-bottom 10px
  a
    color white
    &:hover
      text-decoration underline
  .close
    color white
    background none
    font-size 2rem
    absolute right 4% top 20px
    z-index 1001
    &:hover
      color darken(white, 15%)
  &.in
    display block
    animation modal-fade-in 70ms ease-in
  &.out
    display none

  input[type=number]
    max-width inherit
    margin-left 0
    text-align left

.Modal-content
  position relative

.Modal-white
  .Modal-content
    background-color white
    border-radius 16px
    box-shadow 0 0 16px rgba(0,0,0,.8)
    padding 20px 40px
    max-width 800px
    margin 0 auto
    color color-primary
  .Button
    background color-secondary
    color white
    border 0
    &:hover
      background darken(color-secondary, 30%)
  button[type="submit"]
    margin-top 50px
  
  a
    color grey !important

//
.Schedule
  margin 0 auto
  max-width 800px
