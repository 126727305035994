// Contenedores
// ---------------------------------------
.Container
  padding 10px 4%
.Listings
  max-width 1200px
  margin 15px auto
.Content
  width 100% // necesario desde que el Container tiene clase grid por defecto

  // Workaround para layouts responsivos. Toca sobreescribir el ancho de los
  // contenidos
  @media (max-width: 560px)
    width 100% !important

  @media (min-width: 600px)
    padding 20px 40px

.Hero-img
  background url(../img/bg-bd.jpg) center center no-repeat
  background-color lighten(color-light-gray, 40%)
  background-size cover !important
  padding 100px 10px
  font-size 2rem
  text-align center
  color white
  position relative
  &:before
    content ''
    absolute top 0 left 0
    right 0
    bottom 0
    background-color rgba(black, 0.52)
    z-index 1
  > .Content
    font-weight 400
    position relative
    max-width 800px
    margin 0 auto
    z-index 2
    font-family "Quicksand"
  h1, h2, h3
    color white !important

.Caption
  text-align center
  background-color lighten(color-light-gray, 70%)
  font-size 1.6rem


// Hero de cada página
.page-managment
  .Hero-img
    background url(../img/bg-managment.jpg) center center no-repeat
    background-size cover

// services
.Managment-services
  max-width 1160px
  margin 0 auto
  .Node
    background url(../img/managment-services.jpg) center center no-repeat
    background-size cover !important
    color white
    min-height 300px
    position relative
    margin-bottom 20px
    overflow hidden
    a
      color white
    @media (min-width: 560px)
      width 49% !important
      margin 0.5%
    &:before
      absolute top 0 left 0
      right 0
      bottom 0
      background-color rgba(black, 0.7)
      z-index 0
    > h2,
    > p
      position relative
      z-index 1
      transition all 250ms ease-in-out
    > h2
      margin-bottom 0
    > h2,
    > p
      display none

    &:hover
      &:before
        content ''
      > h2,
      > p
        display block
      > h2
        animation fade-from-down 550ms
      > p
        animation fade-from-up 1s

// editor
.page.logged-in .Managment-services .Node
  &:before
    content ''
  > h2
  > p
    display block
    animation none

// Mensajes
.message
  text-align center
  color white
  margin-bottom 30px
  padding 10px
  margin 10px auto 30px
  max-width 400px
  border-radius 8px

  &.success
    background-color rgba(26, 128, 31, 0.8)

  &.error
    background-color rgb(220, 63, 63)
