.front-page-presentation
  h2
    margin-top: 5px !important
    margin-bottom: 5px !important
    color: black
  p:first-child
    margin-bottom: 10px !important
  p
    margin-top: 5px !important
    margin-bottom: 5px !important
    color: black

.frontpage-form-buttons
  display: flex
  flex-flow: row wrap
  align-items: center
  justify-content: space-between
  .container
    position: relative
    width: 100%
    &:hover
      .house_1:before
        background-color: rgba(255, 255, 255, 0.45)
    @media screen and (max-width: responsive-breakpoint)
      width: 100%
      margin-bottom: 20px
    .house_1
      height 300px
      background-image: url("/assets/img/house-1.jpg")
      background-position: center center
      background-size: cover
      display flex
      align-items center
      text-align center
      &:before
        content ' '
        transition: all 250ms ease
        position: absolute
        top: 0
        width: 100%
        height: 300px
        text-align: center
        background-color: rgba(255, 255, 255, 0.75)
      a
        transition: all 250ms ease
        margin 0 auto
        font-size: 1.8vw
        color: dark-blue
        font-weight: bold
        z-index 1
        @media screen and (max-width: responsive-breakpoint)
          font-size: 5vw

