// FORMS
// ============================================================================

$input-control
  -moz-appearance none
  -webkit-appearance none
  background white
  color grey-dark
  display inline-block
  font-size size-normal
  height input-height
  line-height 28px
  padding 4px 8px
  position relative
  vertical-align top
  border-color input-border
  &:active,
  &:focus
    outline none
  &:hover, &:focus
    border-color input-border
  &[disabled]
    &:hover
      background grey-lighter

$input
  @extends $input-control
  display block
  max-width 100%
  width 100%
  border-bottom 1px solid grey-light
  border-top 0px
  border-left 0px
  border-right 0px
  border-radius 0px

  &::-webkit-input-placeholder
    color grey-light
  &::-moz-placeholder
    color grey-light
  &:-ms-input-placeholder
    color grey-light
  &:-moz-placeholder
    color grey-light

input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"]
  @extends $input

textarea
  @extends $input
  line-height: 1.2
  max-height: 600px
  max-width: 100%
  min-height: 120px
  min-width: 100%
  padding: 10px
  resize: vertical

.checkbox,
.radio
  cursor: pointer
  display: inline-block
  line-height: 24px
  padding-left: 30px
  position: relative
  vertical-align: top
  input
    @extends $input-control
    box-shadow: inset 0 1px 1px rgba(black, 0.1)
    cursor: pointer
    float: left
    height: 22px
    left: 0
    outline: none
    padding: 0
    position: absolute
    width: 22px
    &:after
      opacity 0
      position: absolute
    &:checked
      box-shadow: none
      &:after
        opacity: 1
  &:hover
    color: input-color-hover
    input
      border-color: input-border-hover
      &:checked
        border-color: input-border-hover

.checkbox input:after
  arrow(grey-darker, 2px)
  width: 9px
  border-radius: 1px
  height: 6px
  left: 6px
  top: 6px
  transform: rotate(-45deg) scale(1)

.select--block
  display block
  select
    width 100%

.form-field
  margin-bottom 18px

  label
    color grey-light

.form-field_half
  width 48%

label,
label > span //React <0.14 fix
  font-weight bold

.form-message.error
  font-size 15px
  padding 10px
  background #f68b39
  margin-bottom 20px

.label.required
  position relative
  display block
  &:after
    content '*'
    color red
    font-weight bold
    position absolute
    left 8px
    top 35px
  input, select, textarea
    padding-left 19px

.checkbox,
.radio
  cursor: pointer
  display: inline-block
  line-height: 24px
  padding-left: 30px
  position: relative
  vertical-align: top
  input
    @extends $input-control
    box-shadow: inset 0 1px 1px rgba(black, 0.1)
    cursor: pointer
    float: left
    height: 22px
    left: 0
    outline: none
    padding: 0
    position: absolute
    width: 22px
    &:after
      opacity 0
      position: absolute
    &:checked
      box-shadow: none
      &:after
        opacity: 1
  &:hover
    color: input-color-hover
    input
      border-color: input-border-hover
      &:checked
        border-color: input-border-hover

.checkbox input:after
  arrow(primary, 2px)
  width: 9px
  border-radius: 1px
  height: 6px
  left: 6px
  top: 6px
  transform: rotate(-45deg) scale(1)

.radio input
  border-radius: 11px
  &:after
    background: primary
    content ' '
    transform: none
    display block
    width: 10px
    height @width
    border-radius: (@width / 2)
    top: (@width / 2)
    left: (@width / 2)

.form-field:not(.form-field--radio)
  margin 0 auto 1em
  position relative
  &--radio,
  &--checkbox
    display inline-block
    &:not(:last-of-type)
      margin-right 1.3rem

  &:after, &:before
    content ''
    color: input-border
    bottom: 0px;
    border-bottom: 1px solid;
    position: absolute;
    z-index 1
  &:after
    transition: width 0.5s ease, right 0.5s ease;
    width: 0%;
    right: 50%;
  &:before
    transition: width 0.5s ease, left 0.5s ease;
    width: 0%;
    left: 50%;

  &:hover, &:focus
    &:after
      right: 0px;
      width: 50%;
    &:before
      left: 0px;
      width: 50%;
    &:after, &:before
      border-bottom: 2px solid;
      color primary
    label
      color primary

.select
  height input-height
  position relative
  vertical-align middle
  display block
  select
    @extends $input-control
    cursor pointer
    display block
    outline none
    padding-right 36px
    width 100%
    border-bottom 1px solid input-border
    border-top 0px
    border-left 0px
    border-right 0px
    border-radius 0px
    &::ms-expand
      display none
  &:after
    arrow(grey-lighter, 2px)
    margin-top -6px
    right 16px
    top 50%
    
  &.multiple 
    height 100px 
    select 
      height 100px

.checkbox,
.radio
  cursor pointer
  display inline-block
  line-height input-height
  padding-left 30px
  position relative
  vertical-align top
  input
    @extends $input-control
    cursor pointer
    float left
    height 22px
    left 0
    outline none
    padding 0
    position absolute
    width 22px
    border 2px solid grey-dark
    transition border-color 0.5s ease
    &:after
      opacity 0
      position absolute
    &:checked
      border 2px solid primary
      &:after
        opacity 1
  &:hover
    color input-color-hover
    input
      border-color input-border-hover
      &:checked
        border-color input-border-hover

.checkbox input:after
  arrow(grey, 3px)
  width 9px
  border-radius 1px
  height 6px
  left 4px
  top 6px
  transform rotate(-45deg) scale(1)

.radio input
  border-radius: 11px
  &:after
    background: primary
    content ' '
    transform: none
    display block
    width: 10px
    height @width
    border-radius: (@width / 2)
    top: 4px
    left: 4px

#general_contact, #schedule, #request_info
  .form-field
    &:after, &:before
      content None
    label
      color white !important
    &:hover, &:focus
      label
        color white !important
    .select
      &:after
        border-color grey-dark
