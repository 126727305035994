
.site-footer
  background-color grey-light
  color grey-dark
  padding 10px 4%
  position relative
  .footer-container
    align-items: center
    .logo-copy
      width: 100%
      align-items: center
      text-align center
      .img-trust
        width: 65px
        margin-top 2px
      .copyright
        font-size: 0.95vw
        margin 0 auto
      @media screen and (max-width: responsive-breakpoint)
        width: 100%
        justify-content: center
        .img-trust
          width: 70%
        .copyright
          font-size: 14px
          margin 10px 0
    a
      color black
      &:hover
        text-decoration underline

  .footer-xpage-login
    text-align center
    font-size: 14px;
    width 100%
    
  .img-ndoto 
    width 120px
    margin-top 10px

  @media (min-width: responsive-breakpoint)
    padding 2px 4%
    
    .copyright
      display flex
      align-items center
      
      .img-ndoto
        margin-top 0px
        margin-left 5px
        width 80px

    .footer-xpage-login
      position: absolute;
      bottom: 5px;
      right: 4%;
      width initial
      font-size: 12px;
