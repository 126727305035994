.search-app
  padding: 0
  margin-top 40px
  > div
    width: 100%
  &-data
    width: 100%
    padding: 20px 5% 20px 2.5%
    background: linear-gradient(to bottom, blue, dark-blue)
    h2
      font-size: 2vw
      margin-bottom: 0
      color: white
      @media screen and (min-width: 1024px)
        font-size: 20px
      @media screen and (max-width: responsive-breakpoint)
        font-size: 3.8vw
        margin-bottom: 5px
    form
      width: 100%
      max-width: 1024px
      display: flex
      flex-flow: row wrap
      align-items: center
      justify-content: space-evenly
      input[type="text"]
        width 10%
        height: auto
        line-height: normal
        padding: 4px
        font-size: 1.5vw
        @media screen and (min-width: 1024px)
          font-size: 15px
        @media screen and (max-width: responsive-breakpoint)
          font-size: 3vw
          width: 100%
          margin-bottom: 5px
      select[name="subcategory"], select[name="area"]
        width: 22%
        @media screen and (max-width: responsive-breakpoint)
          width: 49%
      select[name="rooms"], select[name="baths"], select[name="size"]
        width: 10%
        @media screen and (max-width: responsive-breakpoint)
          width: 32%
      select
        font-size: 1.5vw
        background-color: white
        border-radius: 3px
        border: 1px solid grey-light
        padding: 4px 2px
        @media screen and (min-width: 1024px)
          font-size: 15px
        @media screen and (max-width: responsive-breakpoint)
          font-size: 3vw
          margin-bottom: 5px
      button
        color: black
        height: auto
        font-size: 2vw
        @media screen and (min-width: 1024px)
          font-size: 20px
        @media screen and (max-width: responsive-breakpoint)
          padding: 0
          font-size: 3.8vw
          width: 100%
          text-align: center
  &-types
    width: 100%
    display: flex
    text-align center
    &-button
      border: 0
      background-color: grey-dark
      border-radius: 0
      margin-left: 1%
      color: white
      font-size: 3.4vw
      padding: 5px 1%
      height: auto
      font-weight: normal

      &:hover
        color white
        font-weight: bold

      @media (min-width: responsive-breakpoint)
        font-size: 2vw
        padding: 20px 1%
      @media (min-width: normal-breakpoint)
        font-size: 20px
        &:first-child
          min-width 120px
        &:nth-child(2)
          min-width 200px
        &:nth-child(3)
          min-width 210px
        &:nth-child(4)
          min-width 200px
        &:nth-child(5)
          min-width 210px
      &:active
        box-shadow: 0 0 0 transparent
    .selected
      background-color: blue
      font-weight: bold
      color white
