.mortgage-app
  max-width 700px
  margin 0 auto
  table
    margin-top: 20px
    border: 1px solid grey
    thead
      background-color: blue
      tr
        td
          color: white
          font-weight: bold
          text-align: center
          padding: 5px
    tbody
      tr:not(:last-child)
        border-bottom: 1px solid grey
      tr
        td
          text-align: center
          padding: 5px
      tr:nth-child(even)
        background-color: grey-lighter
