.publish-form
  position relative
  .pager-navigation > .button
    @extends .button--blue

  // Checkbox inlines continúos
  .checkbox + .checkbox
    margin-left 20px

.publish-form-required-label
  font-style italic
  font-size size-5
  > strong
    color red

.publish-amenities
  >.checkbox
    margin 16px 0
    width 50%
// Correct margin left for contiguous checkbox
.publish-form .publish-amenities .checkbox + .checkbox
  margin-left 0

.publish-attrs
  >.label
    display block

// Terms
.textarea--terms
  max-height 300px
  background-color #eaecf1
  color grey-darker
  overflow-y auto
  padding 20px
  border-radius radius
  font-size .9rem
