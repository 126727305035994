body.home
  .ImageList-galeria
    &.Grid
      margin-left 0px
    .Grid-item
      width 100%
    .Image
      position: relative
      display: flex;
      align-items: center;
      text-align: center;
      padding: 20px;

      span
        color white
        margin 0 auto
        text-transform uppercase
        z-index 20

      &:after
        position absolute
        top 0
        right 0
        left 0
        bottom 0
        content ' '
        transition background-color 200ms ease-in-out
        background-color rgba(black, .25)

  @media (min-width: responsive-breakpoint)
    .ImageList-galeria
      justify-content center
      .Grid-item
        width 33%
      .Image
        span
          font-size 22px
      .Image-wrapper:hover
        .Image:after
          background-color rgba(black, .5)

  @media (min-width: normal-breakpoint)
    .ImageList-galeria
      .Grid-item
        width 25%
