@keyframes loading
  from
    background-color rgba(white, .5)
  to
    background-color rgba(white, .9)

.Loader
  absolute top 0 right 0
  left 0
  bottom 0
  background-color rgba(white, .9)
  background url(../img/loader.gif) center center no-repeat rgba(white, .9)
  animation loading 700ms ease-in infinite alternate


.locker
  absolute top 0 right 0
  left 0
  bottom 0
  background-color rgba(white, .9)
  display flex
  width 100%
  align-items center
  justify-content center
  text-align center
