@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon.eot?-5tj10e');
  src:url('../fonts/icomoon.eot?-5tj10e#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?-5tj10e') format('truetype'),
    url('../fonts/icomoon.woff?-5tj10e') format('woff'),
    url('../fonts/icomoon.svg?-5tj10e#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="sicon-"], [class*=" sicon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sicon-home3:before {
  content: "\e60a";
}
.sicon-office:before {
  content: "\e60b";
}
.sicon-newspaper:before {
  content: "\e60c";
}
.sicon-pencil:before {
  content: "\e60d";
}
.sicon-droplet:before {
  content: "\e60e";
}
.sicon-image:before {
  content: "\e60f";
}
.sicon-camera:before {
  content: "\e610";
}
.sicon-headphones:before {
  content: "\e611";
}
.sicon-music:before {
  content: "\e612";
}
.sicon-spades:before {
  content: "\e613";
}
.sicon-bullhorn:before {
  content: "\e614";
}
.sicon-connection:before {
  content: "\e615";
}
.sicon-feed:before {
  content: "\e616";
}
.sicon-mic:before {
  content: "\e617";
}
.sicon-profile:before {
  content: "\e618";
}
.sicon-file-empty:before {
  content: "\e619";
}
.sicon-stack:before {
  content: "\e61a";
}
.sicon-price-tag:before {
  content: "\e61b";
}
.sicon-cart:before {
  content: "\e61c";
}
.sicon-coin-dollar:before {
  content: "\e61d";
}
.sicon-credit-card:before {
  content: "\e61e";
}
.sicon-lifebuoy:before {
  content: "\e61f";
}
.sicon-phone:before {
  content: "\e620";
}
.sicon-compass2:before {
  content: "\e621";
}
.sicon-map:before {
  content: "\e622";
}
.sicon-display:before {
  content: "\e623";
}
.sicon-mobile2:before {
  content: "\e624";
}
.sicon-tv:before {
  content: "\e625";
}
.sicon-bubble:before {
  content: "\e626";
}
.sicon-user:before {
  content: "\e627";
}
.sicon-enlarge:before {
  content: "\e628";
}
.sicon-enlarge2:before {
  content: "\e629";
}
.sicon-key:before {
  content: "\e62a";
}
.sicon-lock:before {
  content: "\e62b";
}
.sicon-equalizer2:before {
  content: "\e62c";
}
.sicon-cog:before {
  content: "\e62d";
}
.sicon-magic-wand:before {
  content: "\e62e";
}
.sicon-aid-kit:before {
  content: "\e62f";
}
.sicon-stats-dots:before {
  content: "\e630";
}
.sicon-gift:before {
  content: "\e631";
}
.sicon-glass:before {
  content: "\e632";
}
.sicon-mug:before {
  content: "\e633";
}
.sicon-spoon-knife:before {
  content: "\e634";
}
.sicon-leaf:before {
  content: "\e635";
}
.sicon-rocket:before {
  content: "\e636";
}
.sicon-meter:before {
  content: "\e637";
}
.sicon-bin:before {
  content: "\e638";
}
.sicon-truck:before {
  content: "\e639";
}
.sicon-road:before {
  content: "\e63a";
}
.sicon-switch:before {
  content: "\e63b";
}
.sicon-power-cord:before {
  content: "\e63c";
}
.sicon-earth:before {
  content: "\e63d";
}
.sicon-flag:before {
  content: "\e63e";
}
.sicon-eye:before {
  content: "\e63f";
}
.sicon-bookmark:before {
  content: "\e640";
}
.sicon-sun:before {
  content: "\e641";
}
.sicon-star-empty:before {
  content: "\e642";
}
.sicon-checkmark:before {
  content: "\e643";
}
.sicon-enter:before {
  content: "\e644";
}
.sicon-loop2:before {
  content: "\e645";
}
.sicon-shuffle:before {
  content: "\e646";
}
.sicon-folder-download:before {
  content: "\e605";
}
.sicon-calendar:before {
  content: "\e608";
}
.sicon-clock:before {
  content: "\e609";
}
.sicon-location:before {
  content: "\e604";
}
.sicon-cross:before {
  content: "\e600";
}
.sicon-skype:before {
  content: "\e603";
}
.sicon-menu:before {
  content: "\e606";
}
.sicon-twitter:before {
  content: "\e601";
}
.sicon-facebook:before {
  content: "\e602";
}
.sicon-instagram:before {
  content: "\e607";
}

