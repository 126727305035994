.Featured
  padding 0px

  h2
    font-size 1.7em
    color grey-darker

  .ImageList-carousel
    margin-bottom 0px
    .owl-item, .owl-wrapper
      margin-right 0px

    .owl-item
      overflow: hidden;
      &:before, &:after
        position absolute
        top 0
        right 0
        left 0
        bottom 0

      &:after
        content ''
        background-color rgba(blue, 0.7)
        transition background-color 200ms ease-in-out
        transform translate3d(0,-100%,0)
        transition all 500ms ease
      &:hover
        &:after
          transform translate3d(0,0,0)
        .listing
          transform translate3d(0,0,0)
          opacity 1 !important
          top 5%
          bottom 10%

      .listing
        position absolute
        top 0px
        left 0px
        right 0px
        color white
        font-size 20px
        z-index 8
        opacity 0
        padding 10px
        text-align center
        transition background-color 200ms ease-in-out
        transform translate3d(0,-100%,0)
        transition all 500ms ease

        h4
          margin-bottom 15px

        span
          font-weight bold

        .atributes
          padding 10px 0px
          height: 65%;
          display: flex;
          flex-direction column
          align-items: center;
          text-align: center;
          justify-content center

        .button
          position absolute
          bottom 0px
          left: 0;
          right: 0;
          max-width: 215px;
          margin: 0 auto;
