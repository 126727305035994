body.logged-in
  .ContainersList
    padding-top 0px !important

body.home:not(.logged-in)
  .ContainersList:not(.inverted)
    padding-top 200px !important

  @media (min-width: 450px) and (max-width: 600px)
    .ContainersList:not(.inverted)
      padding-top 110px !important

  @media (min-width: responsive-breakpoint)
    .ContainersList:not(.inverted)
      padding-top 150px !important

body:not(.home)
  .ContainersList
    padding-top 210px
  .Caption
    padding-top 245px

  @media (min-width: 450px) and (max-width: 850px)
    .ContainersList
      padding-top 110px
    .Caption
      padding-top 160px

  @media (min-width: 850px)
    .ContainersList
      padding-top 145px
    .Caption
      padding-top: 200px;

.Content
  padding 10px 8px 16px // Para separar grid-items
  &.is-medium
    font-size: size-5
    code
      font-size: size-6
  &.is-large
    font-size: size-4
    code
      font-size: size-5
  &:not(:last-child)
    margin-bottom: 20px
  h1,
  h2,
  h3,
  h4,
  h5,
  h6
    color: text-strong
    font-weight: 300
    line-height: 1.125
    margin-bottom: 20px
  h2,
  h3,
  h4,
  h5,
  h6
    font-family family-sans-serif
  h1,
  h2,
  h3
    &:not(:first-child)
      margin-top: 40px
  h1
    font-size: 2em
    font-weight bold
  h2
    font-size: 1.75em
  h3
    font-size: 1.5em
  h4
    font-size: 1.25em
  h5
    font-size: 1.125em
  h6
    font-size: 1em
  p:not(:last-child)
    margin-bottom: 1em
  li + li
    margin-top: 0.25em
  ol
    list-style: decimal outside
    margin: 1em 2em
  ul
    list-style: disc outside
    margin: 1em 2em
    ul
      list-style-type: circle
      margin-top: 0.5em
      ul
        list-style-type: square
  blockquote
    background: background
    border-left: 5px solid border
    padding: 1.5em
    &:not(:last-child)
      margin-bottom: 1em

  @media (max-width: 800px)
    iframe
      width 100% !important

h1
  font-size 1.8rem
h2
  font-size 1.5rem
h3
  font-size 1.4rem

@media (min-width: responsive-breakpoint)
  h1
    font-size 3.5rem
  h2
    font-size 2.5rem
  h3
    font-size 2rem

.Social
  display flex
  align-items center
  max-width 1100px

  .fb-share-button
    margin-bottom 0px
    margin-right 5px


.image--background
  width 100%
  background-repeat no-repeat
  background-position 50%
  background-size cover
  height 70vw

  &.carousel
    height 350px

  &.galery
    height 200px

  @media (min-width: responsive-breakpoint)
    height 40vw

    &.carousel
      height 390px

    &.galery
      height 17vw

.ImageList
  .owl
    &-prev, &-next
      width 45px
      height 50px
      border-radius 0px
      background-color transparent

      &:hover
        background-color transparent

    &-next
      background url(/assets/images/arrow-right.png) no-repeat
      &:before
        content ''

    &-prev
      margin-left 15px
      background url(/assets/images/arrow-left.png) no-repeat
      &:before
        content ''

.Formulario
  max-width 700px
  margin 0 auto

.Event
  &-Single
    .Container
      margin auto

.splide__slide.is-active.is-visible.splide__slide--has-video
  width: 100%;

.splide
  @media (min-width: responsive-breakpoint)
    .splide__list
      img
        width: 100vw;
        height: 87vh;
