.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.sticky:before,
.sticky:after {
  content: '';
  display: table;
}
