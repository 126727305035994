
.site-header
  padding 0 4%
  background linear-gradient(to bottom, blue, dark-blue)
  border-bottom 2px solid color-light-gray
  align-items center
  z-index 2
  position relative
  .nav-menu
    width: 100%
    height: auto
    display: none
    ul.list-menu
      .item-menu
        height initial

        .list-menu
          background-color dark-blue
          display initial !important
          visibility hidden
          transition all 500ms ease
          opacity 0
          position absolute
          text-align center
          padding 0px 8px

  .list-menu:first-child
    display: flex !important
    flex-flow: row wrap
    justify-content: space-between
  .list-menu
    text-transform capitalize
    > .item-menu:hover
      background-color: transparent
    > .item-menu
      margin 0
      height: auto
      padding: 10px 0
      &:hover
        > .list-menu
          visibility visible !important
          opacity 1 !important

      > .list-menu
        .item-menu
          padding: 10px 10px !important
          background-color: dark-blue
        .item-menu:hover
          background-color: dark-blue

    .link-menu
      color white
      font-size: 0.6em

  @media (min-width: 650px)
    .list-menu
      .link-menu
        font-size: 0.7em

  @media (min-width: normal-breakpoint)
    .list-menu
      .link-menu
        font-size: 1em

    #menuprincipal
      max-width 1200px
      margin 0 auto

// Overwrite del creador de menus
// Boo!
#fondo-opaco
  z-index 11

.aux-nav
  .item-menu
    display block
    float none
    height auto
    background-color transparent !important

    &:hover
      display block

      .list-menu
        position relative
        background-color transparent

    .list-menu
      display block
      left 0px

      .item-menu
        padding 0px 5px 0px 20px

  .link-menu
    font-size 1.2em
    padding 15px 0
    display block
    border-bottom 1px dashed #8C8585
    text-transform capitalize

.aux-nav__btn
  background transparent
  border 0
  display block
  padding 0
  font-size 32px
  line-height 1
  color: white
  &:hover
    color black

.site-navigation
  display none

// responsive menu
@media (min-width: 600px)
  .aux-nav__btn
    display none
  .site-header
    .nav-menu
      display block
