
.Contact
  background-color #232323
  background-size cover
  color white
  padding 70px 6% 40px
  text-align center
  margin-top 40px
  > .wrapper
    margin 0 auto
    max-width 500px
  .huge
    font-size 2rem
    font-family "Quicksand", "Lato", "Helvetica"
    margin-top 0

.Contact-model_content
  font-size 1.3rem
  @media (min-width: 700px)
    display flex
    flex-flow row wrap
    justify-content space-around
    .Modal_reach
      width 45%
      margin-right 2%
      order 1
    .Modal_form
      width 53%
      order 2

.Contact-modal_reach
  .social a
    display block
    margin-bottom 18px
    font-size 1.5rem
    transition all 250ms ease-out
    &:hover
      transform translate3d(15px,0,0)

.Contact-modal_form
  max-width 600px
