button.flat
  border none

button.Button
  background-color transparent
  color gray
  width 100%

.Button
  padding 8px 35px
  display inline-block
  text-underline none
  border 1px solid gray
  transition all 250ms ease-in-out
  display inline-block
  &--blue
    background-color blue
    color white
    border-color transparent
    &:hover
      color white
      background-color darken(blue, 20%)

.Button-white
  color white
  border-color white
  background-color transparent
  &:hover
    color gray
    background-color white

.Button-block
  display block
  max-width 320px
  margin 5px auto
  text-align center
