
.File-uploader-zone
  border 2px dashed #666
  border-radius 5px
  width 180px
  height 150px
  cursor pointer
  color #636161

.File-uploader-content
  padding 10px
  display flex
  align-items center
  justify-content center
  text-align center
  width 100%
  height 100%

.File-uploader-zone__active
  border-style solid
  background-color #eee
